import { useSetState } from "@mantine/hooks";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { icons } from "./Constants";
import { ArrowBadgeLeft, ArrowBadgeRight } from "tabler-icons-react";
import { capitalizeFirstLetter, range } from "../CMS/logic/HelperFunctions";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { DateInput, DatePicker } from "@mantine/dates";
import { Checkbox, Divider } from "@mantine/core";
import { IKImage } from "imagekitio-react";
import FoodDiary from "./FoodDiary";
import { LifeDiary } from "./LifeDiary";
import { ThirdDiary } from "./ThirdDairy";
import { Hello } from "./Hello";

export const Play = (props) => {
  const defaultState = {
    t_horta: {
      total: 9,
      done: 0,
      currentHoverIdx: -1,
    },
    t_frutas: {
      total: 5,
      done: 0,
      currentHoverIdx: -1,
    },
    t_cereais: {
      total: 4.5,
      bad: 1.5,
      done: 0,
      currentHoverIdx: -1,
    },
    t_protein: {
      total: 2.5,
      bad: 1.5,
      done: 0,
      currentHoverIdx: -1,
    },
    t_leite: {
      total: 1.5,
      bad: 0.5,
      done: 0,
      currentHoverIdx: -1,
    },
    t_gordu: {
      total: 2.5,
      bad: 0.5,
      done: 0,
      currentHoverIdx: -1,
    },
    t_agua: { total: 10, done: 0, currentHoverIdx: -1 },
    m_fis: false,
    m_mem: false,
    m_vista: false,
    m_alg: false,
    m_res: false,
    m_jejum: false,
    m_pc: false,
    m_masti: false,
    m_his: false,
    s_oleo: false,
    s_ezeq: false,
    s_suple: false,
    s_prop: false,
    s_chl: false,
    s_vita: false,
    s_sol: false,
    s_agua: false,
    s_ar: false,
  };

  const keyToWordDict = {
    t_horta: "Hortaliças",
    t_frutas: "Frutas",
    t_cereais: "Cereais",
    t_protein: "Proteínas",
    t_leite: "Leite",
    t_gordu: "Gorduras",
    t_agua: "Água",
    // ... add other key mappings as needed
  };

  const [state, setState] = useSetState();
  const [stateDones, setStateDones] = useState({});

  const [currentDate, setCurrentDate] = useState(new Date());

  // Immediately upload changes
  useEffect(() => {
    console.log("did init load", props.loadedData);
    const updateFirestore = async () => {
      // Only change data when the initial data was loaded (otherwise empty overwrite)
      if (state && props.loadedData) {
        try {
          // Get today's date in the format 'YYYY-MM-DD'
          const dateKey = currentDate.toISOString().split("T")[0];

          // Update the Firestore document
          const userDocRef = doc(props.db, "users", props.user.id);
          await setDoc(userDocRef, { [dateKey]: state }, { merge: true });

          console.log("Form state updated in Firestore");
        } catch (error) {
          console.error("Error updating Firestore:", error);
        }
      }
    };

    updateFirestore();
  }, [stateDones]);

  const stateDonesRef = useRef({});

  useEffect(() => {
    const newStateDones = {};
    console.log("state changed", state);

    for (const key in state) {
      if (typeof state[key] === "object" && "done" in state[key]) {
        newStateDones[key] = state[key].done;
      } else if (typeof state[key] === "boolean") {
        newStateDones[key] = state[key];
      }
    }

    console.log("updates stateDones", newStateDones);

    // Check if the done values have changed
    if (
      JSON.stringify(newStateDones) !== JSON.stringify(stateDonesRef.current)
    ) {
      stateDonesRef.current = newStateDones;
      setStateDones(newStateDones);
    }
  }, [state]);

  useEffect(() => {
    // Get the date key in the format 'YYYY-MM-DD'
    const dateKey = currentDate.toISOString().split("T")[0];

    // Update the state with data for the new date key
    if (props.userData && props.userData[dateKey]) {
      console.log("setting state with doc data", dateKey);
      setState(props.userData[dateKey]);
    } else {
      // Reset the state to default values if no data exists for the new date key
      setState(JSON.parse(JSON.stringify(defaultState)));
    }
  }, [currentDate, props.loadedData]);

  return (
    <div className="w-screen">
      <div className="bg-gradient-to-t h-full z-0  top-0 left-0 flex flex-col justify-end from-stone-200  to-white w-full pb-32">
        <div className=" flex justify-center my-16">
          {" "}
          <DateNavigation
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
          ></DateNavigation>
        </div>
        <IKImage
          path={"/nut"}
          className="absolute h-16 md:h-52  filter drop-shadow z-10 bottom-20 left-48 "
          loading="lazy"
          lqip={{ active: true }}
        ></IKImage>
        <IKImage
          path={"/orange.png"}
          className="absolute h-32 md:h-52  filter drop-shadow z-10 top-32 left-48 "
          loading="lazy"
          lqip={{ active: true }}
        ></IKImage>
        <IKImage
          path={"/orange.png"}
          className="absolute h-16  md:h-48 z-0 filter drop-shadow z-10 top-56 left-32 "
          loading="lazy"
          lqip={{ active: true }}
        ></IKImage>
        <IKImage
          path={"/orange.png"}
          className="absolute h-16  md:h-40  filter drop-shadow z-10 top-52 left-40 "
          loading="lazy"
          lqip={{ active: true }}
        ></IKImage>
        {/* <IKImage
        path={"/orange.png"}
        className="absolute  h-64 z-10 top-64 filter drop-shadow filter brightness filter saturation right-48 "
        loading="lazy"
        lqip={{ active: true }}
      ></IKImage> */}
        <IKImage
          path={"/pineapple.webp"}
          style={{ height: 700 }}
          className="md:absolute m-auto z-0 hidden  bottom-0 filter drop-shadow filter brightness filter saturation -right-10 "
          loading="lazy"
          lqip={{ active: true }}
        ></IKImage>
        <div className=" z-50 mx-auto flex flex-col gap-8 p-2">
          <Hello userData={props.userData} />
          <FoodDiary
            state={state}
            setState={setState}
            metadata={props.metadata}
          />
          <div className="flex flex-col md:flex-row gap-4 justify-between">
            {" "}
            <LifeDiary state={state} setState={setState} />
            <ThirdDiary state={state} setState={setState} />
          </div>
        </div>
      </div>
    </div>
  );
};

const DateNavigation = (props) => {
  const formatDate = (date) => {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("pt", options);
  };

  const navigateDate = (offset) => {
    const newDate = new Date(props.currentDate);
    newDate.setDate(props.currentDate.getDate() + offset);
    props.setCurrentDate(newDate);
  };

  return (
    <div className="flex bg-transparent rounded-xl items-center">
      <button
        className="rounded-full  mr-1 hover:-translate-x-1 transition-all duration-200 w-6 h-6"
        onClick={() => navigateDate(-1)}
      >
        <ArrowBadgeLeft size={24}></ArrowBadgeLeft>
      </button>
      <div className="flex  items-center flex-col">
        {" "}
        <h1 className="text-center  text-3xl text-neutral-700   mx-1 items-center z-20 w-full bg-white pointer-events-none	 mx-auto  h-10 mx-4">
          {formatDate(props.currentDate)}
        </h1>
        <DateInput
          className="mx-8 my-auto bg-transparent opacity-100 z-0 -mt-10"
          value={props.currentDate}
          onChange={props.setCurrentDate}
        />
      </div>

      {/* <h1 className="mx-4">{formatDate(props.currentDate)}</h1> */}
      <button
        className="rounded-full  ml-1 hover:translate-x-1 transition-all duration-200 w-6 h-6"
        onClick={() => navigateDate(1)}
      >
        <ArrowBadgeRight size={24}></ArrowBadgeRight>
      </button>
    </div>
  );
};
