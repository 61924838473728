import React from "react";
import { icons } from "./Constants";
export const SelectableIcon = (props) => {
  return (
    <div
      onMouseEnter={() => {
        const modObj = props.state[props.dictkey];
        props.state[props.dictkey].currentHoverIdx = props.idx + 1;
        props.setState({ [props.dictkey]: modObj });
      }}
      onMouseLeave={() => {
        const modObj = props.state[props.dictkey];
        props.state[props.dictkey].currentHoverIdx = -1;
        props.setState({ [props.dictkey]: modObj });
      }}
      onClick={() => {
        const modObj = props.state[props.dictkey];
        props.state[props.dictkey].currentHoverIdx = -1;
        props.state[props.dictkey].done = props.idx + 1;
        props.setState({ [props.dictkey]: modObj });
      }}
      key={props.idx}
      className={`cursor-pointer w-4  ${
        props.idx < props.state[props.dictkey].currentHoverIdx ||
        props.idx < props.state[props.dictkey].done
          ? "opacity-100 "
          : "opacity-50"
      }`}
    >
      {icons[props.dictkey](
        props.idx < props.state[props.dictkey].currentHoverIdx ||
          props.idx < props.state[props.dictkey].done
      )}
      {/* {props.idx < props.state[props.dictkey].done && (
        <Check size={36} className="-mt-8 text-amber-600   "></Check>
      )} */}
    </div>
  );
};
