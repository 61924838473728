import { Button, Modal, TextInput } from "@mantine/core";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { IKImage } from "imagekitio-react";
import React, { useState } from "react";
import { BiFoodMenu, BiGame, BiHealth, BiMailSend } from "react-icons/bi";
import { BsApple, BsGoogle } from "react-icons/bs";
import { FaPrayingHands } from "react-icons/fa";
import {
  GiBanana,
  GiGrowth,
  GiHeartInside,
  GiMuscleUp,
  GiPrayer,
  GiProgression,
} from "react-icons/gi";
import { PiHeartHalfLight } from "react-icons/pi";
import { TbGrowth, TbHealthRecognition } from "react-icons/tb";
import { useNavigate } from "react-router";
import { ArticleSection } from "../ArticleSection";
import { Categories } from "../Categories";

export const Home = (props) => {
  const [opened, setOpened] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const auth = getAuth();

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in successfully
        const user = userCredential.user;
        console.log(user);
        navigate("/play");
        setOpened(false);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate("/play");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        setError(errorMessage);
      });
  };

  return (
    <div className="w-screen relative">
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Sign in with Email"
      >
        <TextInput
          label="Email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <TextInput
          label="Password"
          placeholder="Enter your password"
          value={password}
          onChange={handlePasswordChange}
          type="password"
          required
        />
        {error && <div style={{ color: "red" }}>{error}</div>}
        <Button className="bg-blue-400 mt-8" onClick={handleSignIn}>
          Sign In
        </Button>
      </Modal>
      <div className="h-128 mt-12 flex-col md:flex-row flex justify-between w-full">
        <div className="md:w-2/3 w-full md:h-128 flex  flex-col items-center justify-center">
          <div className="mt-16 md:mt-32 px-2 md:px-0">
            <h2
              style={{ zIndex: -10 }}
              className="relative mx-auto bg-gradient-to-tr text-center from-amber-500 to-lime-300 via-yellow-200 text-transparent bg-clip-text text-6xl md:text-8xl opacity-25 font-bold"
            >
              HEALTH AND FAITH
            </h2>
            <h1
              style={{ zIndex: 30 }}
              className="mx-auto text-stone-600 z-50 -mt-32 text-center  text-5xl font-bold"
            >
              The #1 App to track your Life and Faith
            </h1>
          </div>
          <div className="flex flex-col md:flex-row mx-auto mt-8 md:mt-32 justify-center items-end gap-2 z-50">
            {" "}
            <Button
              variant="filled"
              className="bg-blue-500"
              leftIcon={<BiMailSend size={24} />}
              onClick={() => setOpened(true)}
            >
              Sign in with Email
            </Button>
            <Button
              variant="filled"
              color="red"
              className="bg-red-500 hover:bg-red-600"
              leftIcon={<BsGoogle size={18} />}
              onClick={handleGoogleSignIn}
            >
              Sign in with Google
            </Button>
          </div>
        </div>
        <div className="md:w-1/2  z-20">
          {" "}
          <IKImage
            path={"/phone.png"}
            className=" h-full mt-8 md:mt-32 drop-shadow z-50 ml-2 md:ml-0 "
            loading="lazy"
            lqip={{ active: true }}
          ></IKImage>
        </div>
      </div>
      <div className="relative mt-40 md:mt-0 flex justify-center items-center z-0 bg-gradient-to-tr from-amber-500 to-lime-300 via-yellow-200 h-128 ">
        <div className="flex flex-wrap gap-4 mx-auto mt-16 justify-center">
          <div className="bg-white backdrop-blur-md bg-opacity-75 rounded-xl shadow-xl p-4 text-stone-600 font-bold flex gap-4 items-center text-lg">
            <TbHealthRecognition size={30} /> Intuitively track your health
          </div>
          <div className="bg-white backdrop-blur-md bg-opacity-75 rounded-xl shadow-xl p-4 text-stone-600 font-bold flex gap-4 items-center text-lg">
            <GiHeartInside size={24} /> Track your life easily without effort
          </div>
          <div className="bg-white backdrop-blur-md bg-opacity-75 rounded-xl shadow-xl p-4 text-stone-600 font-bold flex gap-4 items-center text-lg">
            <GiMuscleUp size={24} /> Monitor your growth and progress
          </div>
        </div>
        <div class="custom-shape-divider-top-1704198255">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
      <div className="relative">
        <div class="custom-shape-divider-bottom-1704200224">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>

      <div className="my-32 p-8 font-bold text-lg flex flex-col gap-8 items-center mx-auto">
        <span className="flex gap-4  items-center text-stone-600 max-w-lg">
          <FaPrayingHands size={32} className="w-20  grow-0 shrink-0" />
          <div>
            <h4>Faithful Eating Journal</h4>
            <p className="text-stone-400 text-sm font-light">
              Track not just what you eat, but how you feel, aiding in mindful
              eating practices.
            </p>
          </div>
        </span>
        <span className="flex gap-4  items-center text-stone-600 max-w-lg">
          <PiHeartHalfLight size={40} className="w-20  grow-0 shrink-0" />
          <div>
            <h4>Health Insights at a Glance</h4>
            <p className="text-stone-400 text-sm font-light">
              Get instant nutritional information on your eating habits, with
              color-coded alerts for balanced nutrition.
            </p>
          </div>
        </span>
        <span className="flex gap-4  items-center text-stone-600 max-w-lg">
          <BiGame size={36} className="w-20  grow-0 shrink-0" />
          <div>
            <h4>Gamified Challenges</h4>
            <p className="text-stone-400 text-sm font-light">
              Engage in fun challenges to meet daily health goals, earn rewards,
              and track progress with playful badges and points.
            </p>
          </div>
        </span>
        <span className="flex gap-4  items-center text-stone-600 max-w-lg">
          <GiProgression size={32} className="w-20  grow-0 shrink-0" />
          <div>
            <h4>Real-time Progress Reports</h4>
            <p className="text-stone-400 text-sm font-light">
              Visualize your journey with dynamic charts and graphs reflecting
              your progress over time.
            </p>
          </div>
        </span>
      </div>
      <ArticleSection db={props.db} />
      <Categories db={props.db} metadata={props.metadata} />
      <div className="relative   z-50">
        <div class="custom-shape-divider-top-1704198255">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};
