import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div className="relative w-full  bg-gradient-to-tr from-amber-500 to-lime-300 via-yellow-300  text-white flex p-8">
      <div className="flex gap-2 z-50 h-full flex flex-col justify-center items-center mx-auto text-white mt-16 font-bold">
        <p>© deplanbook.com</p>
        <Link to="/play">
          {" "}
          <p className="font-light underline transition-all duration-200 hover:text-sky-500 cursor-pointer">
            My PlanBook
          </p>
        </Link>
        <Link to="/posts">
          {" "}
          <p className="font-light underline transition-all duration-200 hover:text-sky-500 cursor-pointer">
            Deep Study Guide
          </p>
        </Link>
        <Link to="https://www.garzen.nl">
          {" "}
          <span className="flex gap-1 font-light">
            Web Development by:
            <p className=" underline transition-all duration-200 hover:text-sky-500 cursor-pointer">
              Garzen.nl
            </p>
          </span>
        </Link>
      </div>
    </div>
  );
};
