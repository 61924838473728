import React, { useState } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import { Select } from "@mantine/core";

const collectData = (data, startDate, endDate, frequency) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const collectedData = {};

  for (const dateStr in data) {
    const date = moment(dateStr);
    if (date.isBetween(start, end, undefined, "[]")) {
      const key = date.startOf(frequency).format();

      if (!collectedData[key]) {
        collectedData[key] = {};
      }

      for (const category in data[dateStr]) {
        if (!collectedData[key][category]) {
          collectedData[key][category] = [];
        }

        collectedData[key][category].push(data[dateStr][category]["done"]);
      }
    }
  }

  console.log(collectedData);

  return collectedData;
};

const applyAggregationFn = (collectedData, aggregationFn) => {
  const aggregatedData = {};

  for (const key in collectedData) {
    console.log(key);
    aggregatedData[key] = {};

    for (const category in collectedData[key]) {
      if (!category.includes("m_")) {
        aggregatedData[key][category] = aggregationFn(
          collectedData[key][category]
        );
      }
    }
  }

  return aggregatedData;
};

export const Data = (props) => {
  const [startDate, setStartDate] = useState("2021-01-01");
  const [endDate, setEndDate] = useState("2023-12-31");
  const [frequency, setFrequency] = useState("");

  const collectedData = collectData(
    props.userData,
    startDate,
    endDate,
    frequency
  );

  const average = (values) => {
    return values.reduce((sum, value) => sum + value, 0) / values.length;
  };

  const sum = (values) => {
    return values.reduce((sum, value) => sum + value, 0);
  };

  const aggregationFns = { sum: sum, average: average };

  const [chosenAggrFn, setChosenAggrFn] = useState("sum");

  console.log(aggregationFns[chosenAggrFn]);
  const aggregatedData = applyAggregationFn(
    collectedData,
    aggregationFns[chosenAggrFn] || sum
  );
  console.log(aggregatedData);

  return (
    <div>
      <div className="mx-auto flex gap-2 justify-around">
        <div className="mx-auto my-8 flex flex-column gap-4">
          <DatePicker value={startDate} onChange={setStartDate} />
          <DatePicker value={endDate} onChange={setEndDate} />
        </div>{" "}
        <Select
          label="Frequentie"
          placeholder="week"
          searchable
          onSearchChange={setFrequency}
          searchValue={frequency}
          nothingFound="No options"
          data={["week", "month", "year", "day"]}
        />
        <Select
          label="Aggregatie"
          placeholder="sum"
          searchable
          onSearchChange={setChosenAggrFn}
          searchValue={chosenAggrFn}
          nothingFound="No options"
          data={["sum", "average"]}
        />
      </div>

      <AggregatedDataTable
        aggregatedData={aggregatedData}
        frequency={frequency}
      ></AggregatedDataTable>
    </div>
  );
};

const formatDateLabel = (dateLabel, frequency) => {
  const date = moment(dateLabel);

  switch (frequency) {
    case "year":
      return date.format("YYYY");
    case "month":
      return date.format("MMMM YYYY");
    case "week":
      return `Week ${date.week()} ${date.format("YYYY")}`;
    case "day":
      return date.format("MMMM Do YYYY");
    default:
      return dateLabel;
  }
};

const AggregatedDataTable = ({ aggregatedData, frequency }) => {
  const dateLabels = Object.keys(aggregatedData);
  const categories = new Set();

  for (const key in aggregatedData) {
    // Currently not the booleans yet
    for (const category in aggregatedData[key]) {
      categories.add(category);
    }
  }

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Date
          </th>
          {Array.from(categories).map((category) => (
            <th
              key={category}
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {category}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {dateLabels.map((dateLabel, index) => (
          <tr
            key={dateLabel}
            className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
          >
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {formatDateLabel(dateLabel, frequency)}
            </td>
            {Array.from(categories).map((category) => (
              <td
                key={category}
                className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
              >
                {aggregatedData[dateLabel][category] || "-"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
