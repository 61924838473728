import {
  BsClockHistory,
  BsDribbble,
  BsEmojiAngry,
  BsEmojiSmile,
  BsEyeSlash,
  BsFillStopwatchFill,
  BsFlower1,
  BsLaptop,
  BsStrava,
  BsWater,
} from "react-icons/bs";
import {
  TbArcheryArrow,
  TbHttpOptions,
  TbMatchstick,
  TbMoustache,
  TbRadar,
  TbSalad,
} from "react-icons/tb";
import { Bounce, Fade } from "react-awesome-reveal";
import {
  GiAlgae,
  GiBanana,
  GiBrain,
  GiButterToast,
  GiEntMouth,
  GiFishBucket,
  GiMouthWatering,
  GiOlive,
  GiResonance,
  GiTopHat,
} from "react-icons/gi";
import { CiWheat, CiApple } from "react-icons/ci";
import { BsEgg, BsDroplet } from "react-icons/bs";
import {
  ArrowBadgeLeft,
  ArrowBadgeRight,
  Check,
  DeviceMobileVibration,
  Grain,
  InfoCircle,
  Milk,
} from "tabler-icons-react";
import { BiLabel } from "react-icons/bi";

export const icons = {
  t_horta: (active) => {
    return (
      <TbSalad
        className={`${
          active
            ? "fill-amber-400 text-amber-400 stroke-amber-400"
            : "fill-stone-500"
        }  transition-all duration-200 hover:-translate-y-1 text-stone-500`}
        size={26}
      ></TbSalad>
    );
  },
  t_frutas: (active) => {
    return (
      <CiApple
        className={`${
          active
            ? "fill-amber-400 text-amber-400 stroke-amber-400"
            : "fill-stone-500"
        }  transition-all duration-200 hover:-translate-y-1 text-stone-500`}
        size={26}
      ></CiApple>
    );
  },
  t_cereais: (active) => {
    return (
      <CiWheat
        className={`${
          active
            ? "fill-amber-400 text-amber-400 stroke-amber-400"
            : "fill-stone-500"
        }  transition-all duration-200 hover:-translate-y-1 text-stone-500`}
        size={26}
      ></CiWheat>
    );
  },
  t_protein: (active) => {
    return (
      <BsEgg
        className={`${
          active
            ? "fill-amber-400 text-amber-400 stroke-amber-400"
            : "fill-stone-500"
        }  transition-all duration-200 hover:-translate-y-1 text-stone-500`}
        size={24}
      />
    );
  },
  t_leite: (active) => {
    return (
      <Milk
        className={`${
          active
            ? "fill-amber-400 text-amber-400 stroke-amber-400"
            : "fill-stone-500"
        }  transition-all duration-200 hover:-translate-y-1 text-stone-500`}
        size={26}
      ></Milk>
    );
  },
  t_gordu: (active) => {
    return (
      <GiOlive
        className={`${
          active
            ? "fill-amber-400 text-amber-400 stroke-amber-400"
            : "fill-stone-500"
        }  transition-all duration-200 hover:-translate-y-1 text-stone-500`}
        size={26}
      />
    );
  },
  t_agua: (active) => {
    return (
      <BsDroplet
        className={`${
          active
            ? "fill-amber-400 text-amber-400 stroke-amber-400"
            : "fill-stone-500"
        }  transition-all duration-200 hover:-translate-y-1 text-stone-500`}
        size={22}
      />
    );
  },
  m_alg: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <GiAlgae
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  m_fis: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <GiFishBucket
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  m_his: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <BsClockHistory
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  m_jejum: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <TbMoustache
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  m_masti: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <TbMatchstick
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  m_mem: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <GiBrain
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  m_pc: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <BsLaptop
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  m_res: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <GiResonance
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  m_vista: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <BsEyeSlash
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },

  s_oleo: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <BsEyeSlash
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  s_ezeq: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <TbArcheryArrow
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  s_suple: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <BiLabel
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  s_prop: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <TbRadar
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  s_chl: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <BsDribbble
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  s_vita: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <BsEyeSlash
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  s_sol: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <GiTopHat
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  s_agua: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <TbHttpOptions
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
  s_ar: (active) => {
    return (
      <div
        className={`w-12 rounded-full h-12 flex items-center justify-center ${
          active ? "bg-amber-100" : "bg-stone-100"
        }`}
      >
        <BsStrava
          size={32}
          className={` ${active && "fill-amber-400 stroke-amber-400"}`}
        />
      </div>
    );
  },
};
