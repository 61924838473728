import React from "react";
import { icons } from "./Constants";
import { capitalizeFirstLetter } from "../CMS/logic/HelperFunctions";

export const LifeDiary = ({ state, setState }) => {
  return (
    <div className="bg-white bg-opacity-75 rounded-xl p-8 shadow-lg backdrop-blur-md">
      <h2 className="text-stone-600 font-bold text-2xl text-center mb-4 -mt-2">
        Jesus Salvador
      </h2>
      {state && (
        <div className="grid grid-cols-3 gap-8 max-w-lg  mx-auto">
          {Object.keys(state).map((key, idx) => {
            return (
              key.includes("m_") && (
                <div
                  className="flex cursor-pointer mx-2 flex-col items-center  justify-center"
                  onClick={() =>
                    setState({
                      [key]: !state[key],
                    })
                  }
                >
                  {icons[key](state[key])}{" "}
                  <p className="text-stone-600  text-center">
                    {capitalizeFirstLetter(key.replace("m_", ""))}
                  </p>
                </div>
              )
            );
          })}
        </div>
      )}
    </div>
  );
};
