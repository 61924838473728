import React from "react";
import { AuthorSpan } from "./AuthorSpan";
// import defense from "./geloofsverdediging.svg";
import CMSConfig from "./CMSConfig";
import { Link } from "react-router-dom";

export const SmallCard = (props) => {
  return (
    <Link to={`/${props.category}/${props.post.title}`}>
      <div className="flex flex gap-2 md:w-80 lg:w-96   group hover:-translate-y-1 transition-all duration-200 cursor-pointer rounded justify-around">
        <img
          className="rounded-md  md:w-32 lg:w-40  h-32 object-cover"
          src={props.post[CMSConfig.postImgVarname || "img"] || ""}
        ></img>
        <div>
          <h3 className="font-bold text-xl group-hover:underline">
            {props.post.title}
          </h3>
          <AuthorSpan />
          <p className="text-gray-600 line-clamp-3">
            {props.post.description || props.post.clean_content}
          </p>
        </div>
      </div>
    </Link>
  );
};
