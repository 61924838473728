import logo from "./logo.svg";
import "./App.css";
import React, { Suspense, lazy, useState, useEffect } from "react";
import { IKContext } from "imagekitio-react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

//Import firebase
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getFirestore,
  doc,
  onSnapshot,
  setDoc,
  collection,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import ScrollToTop from "./ScrollToTop";

//
import { Play } from "./pages/Play";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { NavBar } from "./NavBar";
import { Data } from "./pages/Data";
import { Home } from "./pages/Home";
import { Footer } from "./Footer";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

const Dashboard = React.lazy(() => import("./CMS/pages/Dashboard"));
const Editor = React.lazy(() => import("./CMS/pages/Editor"));
const Post = React.lazy(() => import("./pages/Post"));
const More = React.lazy(() => import("./CMS/pages/More"));

// Pages

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAq-TsbyAG6URoFExLjb43L69Dyh1XIbmg",
  authDomain: "deplanbook.firebaseapp.com",
  projectId: "deplanbook",
  storageBucket: "deplanbook.appspot.com",
  messagingSenderId: "339205399948",
  appId: "1:339205399948:web:0542629677b27b8dd022a9",
  measurementId: "G-3JG2QNBXHW",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth();
const functions = getFunctions(app);
connectFunctionsEmulator(functions, "localhost", 5001);

function App() {
  // Track user with auth state
  const [user, loadingUser, errorUser] = useAuthState(auth);

  const [userData, loadingUserData, errorUserData] = useDocumentData(
    // doc(db, "users", user?.uid || "empty")
    doc(db, "users", "userId")
  );

  // Create user data if it does not exist
  useEffect(() => {
    console.log("new user", user?.email);
    if (!userData && !loadingUserData && user && !loadingUser) {
      setDoc(doc(db, "users", user.uid), {
        displayName: user?.displayName,
        email: user?.email,
        bio: "",
        website: "",
        photoUrl: user?.photoURL,
        rights: [],
        rightRequests: [],
        uid: user?.uid,
      });
    }
  }, [user]);

  // Load metadata
  const [metadata, loading, error] = useDocumentData(
    doc(collection(db, "metadata"), "metadata")
  );

  return (
    <BrowserRouter>
      <ScrollToTop />
      <MantineProvider>
        <Notifications />
        <IKContext urlEndpoint="https://ik.imagekit.io/cis/planbook">
          {" "}
          {/* <HeatMap functions={functions} db={db}> */}
          <Suspense fallback={<div>Loading...</div>}>
            <div>
              <Routes>
                {" "}
                <Route
                  path="/dashboard/:tab?"
                  element={
                    <>
                      {" "}
                      <NavBar standalone></NavBar>
                      <Dashboard
                        metadata={metadata}
                        db={db}
                        user={user}
                        signInSuccessUrl="dashboard"
                        firebase={app}
                        functions={functions}
                        auth={auth}
                        analytics={analytics}
                        isValidMail={true}
                        userData={userData}
                        buttonStyles="bg-sky-500 hover:bg-sky-600 text-white"
                      />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/meer"
                  element={
                    <>
                      <NavBar black absolute></NavBar>
                      <More
                        metadata={metadata}
                        db={db}
                        user={user}
                        firebase={app}
                        functions={functions}
                        auth={auth}
                        analytics={analytics}
                      />
                    </>
                  }
                />
                <Route
                  path="/data"
                  element={
                    <Data
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      auth={auth}
                      analytics={analytics}
                      functions={functions}
                      userData={userData}
                    />
                  }
                />
                <Route
                  path="/home/:section?"
                  element={
                    <>
                      <NavBar standalone />
                      <Home
                        metadata={metadata}
                        db={db}
                        user={user}
                        firebase={app}
                        auth={auth}
                        analytics={analytics}
                        functions={functions}
                        userData={userData}
                      />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/play"
                  element={
                    <>
                      <NavBar standalone />{" "}
                      <Play
                        metadata={metadata}
                        db={db}
                        user={user}
                        firebase={app}
                        auth={auth}
                        analytics={analytics}
                        functions={functions}
                        userData={userData}
                      />{" "}
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/:category/:postname"
                  element={
                    <>
                      <NavBar standalone />
                      <Post
                        metadata={metadata}
                        db={db}
                        user={user}
                        firebase={app}
                        functions={functions}
                        auth={auth}
                        analytics={analytics}
                        buttonStyles="p-1 px-3 rounded-lg bg-gradient-to-br from-purple-600 to-yellow-500 text-white text-md font-bold hover:px-5 transition-all duration-200"
                      />
                    </>
                  }
                />
                <Route
                  path="/:category/"
                  element={
                    <>
                      <NavBar standalone />
                      <Post
                        metadata={metadata}
                        db={db}
                        user={user}
                        firebase={app}
                        auth={auth}
                        analytics={analytics}
                        buttonStyles="p-1 px-3 rounded-lg bg-gradient-to-br from-purple-600 to-yellow-500 text-white text-md font-bold hover:px-5 transition-all duration-200"
                      />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/"
                  element={
                    <Home
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      auth={auth}
                      analytics={analytics}
                      functions={functions}
                      userData={userData}
                    />
                  }
                />
              </Routes>
            </div>
          </Suspense>
          {/* </HeatMap>{" "} */}
        </IKContext>
      </MantineProvider>
    </BrowserRouter>
  );
}

export default App;
