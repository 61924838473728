import React from "react";
import { Link } from "react-router-dom";
import { range } from "../CMS/logic/HelperFunctions"; // Adjust the import path as necessary
import { SelectableIcon } from "./SelectableIcon"; // Assuming SelectableIcon is another component
import { IKImage } from "imagekitio-react";

export const FoodDiary = ({ state, setState, metadata }) => {
  const keyToWordDict = {
    t_horta: "Hortaliças",
    t_frutas: "Frutas",
    t_cereais: "Cereais",
    t_protein: "Proteínas",
    t_leite: "Leite",
    t_gordu: "Gorduras",
    t_agua: "Água",
    // ... add other key mappings as needed
  };

  const rowIdxesMargins = [0, 8, 16, 24, 16, 8, 0];

  return (
    <div className="z-50  rounded-xl p-2 md:p-6 flex flex-col md:flex-row gap-2 md:gap-8 shadow-lg bg-opacity-75 bg-white backdrop-blur-md ">
      <div className="flex flex-col justify-between items-center  hidden md:block">
        <h2 className="text-stone-600 font-bold text-2xl text-center mb-4 -mt-2">
          Deus Criador
        </h2>
        <IKImage
          path={"/food2.png"}
          //   style={{ height: 200 }}
          className="w-56 h-56 object-contain rounded-xl"
          loading="lazy"
          lqip={{ active: true }}
        />
      </div>
      {state && (
        <>
          {" "}
          <h2 className="text-stone-600 font-bold text-2xl text-center -mt-2 block md:hidden">
            Deus Criador
          </h2>
          <div className=" flex gap-2 md:gap-8 justify-center md:mt-12">
            <div className="flex flex-col">
              {[
                "t_horta",
                "t_frutas",
                "t_cereais",
                "t_protein",
                "t_leite",
                "t_gordu",
                "t_agua",
              ].map((key, rowIdx) => (
                <Link to={`/posts/${keyToWordDict[key]}`}>
                  <div
                    className={`h-8 ml-${
                      rowIdxesMargins[rowIdx]
                    } flex text-md items-center text-stone-600 ${
                      metadata && keyToWordDict[key] in metadata.identifiers
                        ? "text-green-500 cursor-pointer transition-all duration-300 hover:text-green-700"
                        : "cursor-text"
                    }`}
                  >
                    <p
                      className={`${
                        parseInt(state[key].done)
                          ? "bg-amber-100 text-amber-500"
                          : "bg-stone-100 text-stone-500"
                      } items-center flex rounded-xl px-2 inline-block`}
                    >
                      {keyToWordDict[key]}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
            <div className=" flex flex-col">
              {[
                "t_horta",
                "t_frutas",
                "t_cereais",
                "t_protein",
                "t_leite",
                "t_gordu",
                "t_agua",
              ].map((key, rowIdx) => {
                return (
                  key.includes("t_") && (
                    <div
                      className={`flex gap-1 md:gap-2 h-8 items-center flex-wrap`}
                    >
                      {range(0, parseInt(state[key].total) + 1).map(
                        (item, idx) => {
                          return (
                            <SelectableIcon
                              state={state}
                              key={idx}
                              dictkey={key}
                              idx={idx}
                              setState={setState}
                            />
                          );
                        }
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FoodDiary;
