import React from "react";
import { AuthorSpan } from "./AuthorSpan";
import { CategoryIconsBar } from "./CategoryIconsBar";
// import defense from "./geloofsverdediging.svg";
import CMSConfig from "./CMSConfig";
import { Link } from "react-router-dom";

export const OtherCard = (props) => {
  return (
    <Link to={`/${props.category}/${props.post.title}`}>
      <div className="flex flex-col  group hover:-translate-y-1 transition-all duration-200 cursor-pointer   rounded justify-between">
        <img
          className="rounded-md group-hover:shadow-inset   h-48 object-cover"
          src={props.post[CMSConfig.postImgVarname || "img"] || ""}
        ></img>
        <CategoryIconsBar />
        <h3 className="font-bold text-2xl group-hover:underline">
          {props.post.title}
        </h3>
        <AuthorSpan />
        <p className="text-gray-600 line-clamp-4">
          {props.post.description || props.post.clean_content}
        </p>
      </div>
    </Link>
  );
};
