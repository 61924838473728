import React, { useEffect, useState } from "react";
import { BiHeart } from "react-icons/bi";
import { TbWeight } from "react-icons/tb";
import { GiHeartInside, GiSportMedal } from "react-icons/gi";
import { Button, Progress } from "@mantine/core";
import moment from "moment";
import { Link } from "react-router-dom";

export const Hello = (props) => {
  const [loggedDays, setLoggedDays] = useState(0);
  const [coverage, setCoverage] = useState(0);
  const [booleanCoverage, setBooleanCoverage] = useState(0);
  const [streak, setStreak] = useState(0);

  const calculateBooleanCoverage = (userData) => {
    if (!userData) return 0;

    let totalCount = 0;
    let trueCount = 0;

    Object.values(userData).forEach((dayData) => {
      // If daydata is object
      if (typeof dayData === "object") {
        Object.entries(dayData).forEach(([key, value]) => {
          if (key.startsWith("m_") || key.startsWith("s_")) {
            totalCount++;
            if (value) {
              trueCount++;
            }
          }
        });
      }
    });

    return totalCount > 0 ? (trueCount / totalCount) * 100 : 0;
  };

  // ... other functions (calculateStreak, calculateLoggedDays, calculateCoverage)

  // Function to determine if a day should be counted as logged
  const isDayLogged = (dayData) => {
    for (const key in dayData) {
      if (key.startsWith("t_") && dayData[key].done > 0) {
        return true;
      }
      if ((key.startsWith("m_") || key.startsWith("s_")) && dayData[key]) {
        return true;
      }
    }
    return false;
  };

  const calculateStreak = (userData) => {
    if (!userData) return 0;

    let streak = 0;
    let checkingDay = moment().subtract(1, "days"); // Start from yesterday

    while (
      userData[checkingDay.format("YYYY-MM-DD")] &&
      isDayLogged(userData[checkingDay.format("YYYY-MM-DD")])
    ) {
      streak++;
      checkingDay = checkingDay.subtract(1, "days"); // Go to the previous day
    }

    return streak;
  };

  // Function to calculate the total number of logged days
  const calculateLoggedDays = (userData) => {
    if (!userData) return 0;

    let count = 0;
    for (const dayKey in userData) {
      if (isDayLogged(userData[dayKey])) {
        count++;
      }
    }
    return count;
  };

  // Function to calculate Coverage
  const calculateCoverage = (userData) => {
    let totalDone = 0;
    let totalPossible = 0;

    if (!userData || !Object.keys(userData).length) return 0;

    Object.values(userData).forEach((dayData) => {
      Object.entries(dayData).forEach(([key, value]) => {
        if (key.startsWith("t_")) {
          totalDone += value.done;
          totalPossible += value.total;
        }
      });
    });

    return totalPossible > 0 ? (totalDone / totalPossible) * 100 : 0;
  };

  // ... other functions (calculateStreak, calculateLoggedDays)

  // useEffect hooks
  useEffect(() => {
    if (props.userData) {
      setLoggedDays(calculateLoggedDays(props.userData));
      setCoverage(calculateCoverage(props.userData));
      setBooleanCoverage(calculateBooleanCoverage(props.userData));
      setStreak(calculateStreak(props.userData));
    }
  }, [props.userData]); // Dependency array ensures this runs only when userData changes

  return (
    <div className="bg-white backdrop-blur-md bg-opacity-75 rounded-xl shadow-xl p-4 md:p-8">
      <span className="flex  gap-2 items-center ">
        <p className="text-stone-600 font-bold text-2xl  mb-4 ">Oi</p>{" "}
        <h2 className=" font-bold text-transparent text-2xl bg-clip-text  bg-gradient-to-tr from-amber-500 to-yellow-300 mb-4 ">
          {props.user?.displayName || "Maria"}
        </h2>
        <p className="wave text-stone-600 font-bold text-2xl  mb-4 ">✋</p>{" "}
      </span>
      <div className="flex flex-col md:flex-row justify-between gap-4">
        {" "}
        <div className="flex justify-between">
          {" "}
          <img
            className="w-24 h-24 object-cover rounded-xl"
            src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          />{" "}
          <div className="flex flex-col justify-end md:hidden block">
            {" "}
            <Link to="/data" className="block md:hidden justify-self-end">
              {" "}
              <Button variant="outline" size="xs">
                See more statistics
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex gap-4">
          {" "}
          <div className="flex flex-col justify-between">
            <span className="flex gap-1 text-sm items-center fill-stone-600 text-stone-600">
              <BiHeart className="" />
              Dagen totaal: {loggedDays}
            </span>
            <span className="flex gap-1 text-sm items-center fill-stone-600 text-stone-600">
              <TbWeight className="" />
              Dagen achter elkaar: {streak}
            </span>
            <span className="flex gap-1 text-sm items-center fill-stone-600 text-stone-600">
              <GiSportMedal className="" />
              Voedsel bijgehouden: {Math.round(coverage)}
            </span>
            <span className="flex gap-1 text-sm items-center fill-stone-600 text-stone-600">
              <GiHeartInside className="" />
              Leven bijgehouden: {Math.round(booleanCoverage)}
            </span>
          </div>
          <div className="flex flex-col  py-2 justify-between w-32">
            <Progress value={Math.min(loggedDays, 100)} size={"sm"} />
            <Progress value={Math.min(streak, 100)} size={"sm"} />
            <Progress value={coverage} size={"sm"} />
            <Progress value={booleanCoverage} size={"sm"} />
          </div>
        </div>
        <div className="flex flex-col justify-end hidden md:block">
          <Link to="/data">
            {" "}
            <Button variant="outline" size="xs">
              See more statistics
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
